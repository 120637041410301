<template>
  <div>
    <RepresentacionProporcionalMovil v-if="anchoPantalla < 992" />
    <RepresentacionProporcionalEscritorio v-else />
  </div>
</template>

<script>
import RepresentacionProporcionalMovil from '@/components/Movil/vistas/RepresentacionProporcional.vue'
import RepresentacionProporcionalEscritorio from '@/components/Escritorio/vistas/RepresentacionProporcional.vue'

export default {
  name: 'RepresentacionProporcional',
  components: {
    RepresentacionProporcionalMovil,
    RepresentacionProporcionalEscritorio,
  },
  computed: {
    anchoPantalla () {
      return this.$store.state.anchoPantalla
    }
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021 - Representación Proporcional'
  }
}
</script>
