<template>
  <div class="vista-escritorio" v-if="cargaTerminada">
    <cabecera />
    <cuerpo>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos tabla-responsiva">
            <h2>
              Diputaciones - <span><strong>Casillas Especiales</strong></span>
            </h2>

            <p>
              Cantidad de votos por Representación Proporcional en cada
              casilla especial que conforma la entidad; de acuerdo a la información
              asentada en las actas de escrutinio y cómputo. <br />
              Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos.
              No obstante, al considerar todos los decimales, suman 100%.
            </p>

            <h3>Votos</h3>
            <h4>
              Detalle de votos por Casilla
            </h4>

            <div class="grilla-estado-acta mb-4">
              <procedencia-acta />
            </div>

            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr class="text-center">
                    <th v-for="(columna, index) in votosXRP.datos_nombres_columnas" :key="index">
                      <img
                        v-if="columna.candidatura_logo !== 'sin logo'"
                        :src="columna.candidatura_logo"
                        :alt="columna.candidatura_nombre"
                      />
                      <div v-else >
                        {{columna.candidatura_nombre}}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(casilla, index) in votos"
                    :key="index"
                    class="text-center"
                  >
                    <td>
                      <!-- DEBERÁ IR EL URL DEL ACTA-->
                      <router-link
                        :to="casilla.datos_votos[2].candidatura_votos_cantidad"
                        class="font-weight-bold"
                      >
                        <div>
                          {{ casilla.casilla_descripcion }}
                        </div>
                      </router-link>
                    </td>
                    <td>
                      {{ casilla.datos_votos[1].candidatura_votos_cantidad }}
                    </td>
                    <td class="procedencias">
                      <a
                        :href="calcularUrlActaDigitalizada(casilla.datos_votos[2])"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <nivel-procedencia
                          :procedencia="parseInt(casilla.datos_votos[2].candidatura_logo)"
                        />
                      </a>
                    </td>
                    <td
                      v-for="(voto, key) in casilla.datos_votos.slice(3)" :key="key"
                    >
                      {{ voto.candidatura_votos_cantidad }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row px-3">
              <div class="col-8 mb-3 resumen-votacion">
                <h4 class="mb-2">
                  Resumen de la votación
                </h4>
                <div class="grilla-suma">
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      {{ resumenTotales.candidaturas_no_registradas_numero }} <br />
                      <span class="porcentaje">
                        {{ resumenTotales.candidaturas_no_registradas_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                      Nulos <br />
                      {{ resumenTotales.nulos_numero }} <br />
                      <span class="porcentaje">
                        {{ resumenTotales.nulos_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right">
                    <p class="font-weight-bold">
                      Total de votos <br />
                      <big>{{ resumenTotales.total_numero }}</big> <br />
                      <span class="porcentaje">
                        {{ resumenTotales.total_porcentaje }}%
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <observaciones-actas />
          </div>
        </div>
      </div>

      <div class="text-center">
        <p>
          Los resultados presentados tienen un carácter informativo y no son definitivos,
          por tanto, no tienen efectos jurídicos. <br /> Con base en la Ley Federal del
          Derecho de Autor queda prohíbida cualquier modificación al diseño de este sitio. <br />
          La modificación y/o destrucción de la información y/o contenido total o
          parcial de este sitio, es delito federal de acuerdo al Código Penal Federal.
        </p>
      </div>
    </cuerpo>
    <pie-de-pagina />
    <compartir />
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import useCalcularUrlActaEscaneada from '@/composables/useCalcularUrlActaEscaneada';
import Compartir from '@/components/Compartir.vue';
import ObservacionesActas from '@/components/Movil/componentes/ObservacionesActas.vue';
import ProcedenciaActa from '@/components/ProcedenciaActa.vue';
import NivelProcedencia from '@/components/NivelProcedencia.vue';
import Cabecera from '../componentes/Cabecera.vue';
import Cuerpo from '../componentes/Cuerpo.vue';
import PieDePagina from '../componentes/PieDePagina.vue';

export default defineComponent({
  components: {
    Cabecera, Compartir, Cuerpo, ObservacionesActas, PieDePagina, ProcedenciaActa, NivelProcedencia,
  },
  name: 'RepresentacionProporcionalEscritorio',
  setup(_, { root }) {
    const state = computed(() => root.$store.state);
    const cargaTerminada = computed(() => state.value.cargaTerminada);
    const votosXRP = computed(() => state.value.dRepresentacionProporcional);
    const resumen = computed(() => state.value.dResumenNivelRepresentacionProporcional);
    const longitudResumen = resumen.value.datos_casillas.length;
    const resumenTotales = computed(() => resumen.value.datos_casillas[longitudResumen - 1]);

    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    const votos = computed(() => votosXRP.value.datos_casillas
      .sort((a, b) => a.seccion_id - b.seccion_id));

    return {
      ...useCalcularUrlActaEscaneada(),
      cargaTerminada,
      votosXRP,
      resumen,
      votos,
      resumenTotales,
    };
  },
});
</script>

<style lang="scss" scoped>
.grilla-estado-acta {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  align-items: flex-end;

  .acta-digitalizada {
    .btn {
      background-color: #D2127E !important;
      border-color: transparent;
    }
  }
}

.table-responsive {
  thead {
    tr {
      background-color: #181A2F;
      color: #FFF;

      th {
        vertical-align: middle;
        img {
          max-height: 35px;
          /* max-width: 35px; */
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        img {
          max-height: 50px;
        }
      }
      .votos {
        text-align: center;
        p {
          font-size: 2rem !important;
          font-weight: bold !important;
        }
      }
    }
  }
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #34344E;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        color: #D2127E;
      }
    }
  }
}
</style>
